import React, { useEffect, useRef } from 'react';
import dayjs from 'dayjs';
import * as styles from './timer.module.scss';

const Timer = ( { date, activePresaleText } ) => {
	const timerRef = useRef();
	const activePresale = useRef();

	const daysRef = useRef();
	const hoursRef = useRef();
	const minutesRef = useRef();
	const secondsRef = useRef();

	const showRemaining = ( endDate ) => {
		const today = dayjs();

		const days = endDate.diff( today, 'days' );
		const hours = endDate.diff( today, 'hours' ) - ( days * 24 );
		const minutes = endDate.diff( today, 'minutes' ) - ( 60 * hours ) - ( 60 * 24 * days );
		const seconds = endDate.diff( today, 'seconds' ) - ( 60 * minutes ) - ( 60 * 60 * hours ) - ( 60 * 60 * 24 * days );

		daysRef.current.textContent = days < 10 ? `0${days}` : days;
		hoursRef.current.textContent = hours < 10 ? `0${hours}` : hours;
		minutesRef.current.textContent = minutes < 10 ? `0${minutes}` : minutes;
		secondsRef.current.textContent = seconds < 10 ? `0${seconds}` : seconds;
	};


	useEffect( () => {
		const today = dayjs();
		const endDate = dayjs( date );

		const diff = endDate.diff( today );

		if( diff > 0 ) {
			setInterval(
				() => showRemaining( endDate ),
				1000
			);

			setTimeout( () => {
				timerRef.current.classList.remove( 'hidden' );
			}, 1000 );
		}

		else {
			timerRef.current.style.display = 'none';
			activePresale.current.style.display = 'block';
		}
	}, [] );

	return(
		<>
			<div className={[styles.timer, 'hidden'].join( ' ' )} ref={timerRef}>
				<div className={styles.block}>
					<div ref={daysRef}>00</div>
					<span>days</span>
				</div>
				<div className={styles.block}>
					<div ref={hoursRef}>00</div>
					<span>hours</span>
				</div>
				<div className={styles.block}>
					<div ref={minutesRef}>00</div>
					<span>minutes</span>
				</div>
				<div className={styles.block}>
					<div ref={secondsRef}>00</div>
					<span>seconds</span>
				</div>
			</div>
			<h3 className={styles.activePresale} ref={activePresale}>Already started! 🚀</h3>
		</>
	);
};

export default Timer;
