import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Seo from '../components/seo/seo';
import Scroller from '../components/scroller/scroller';
import Header from '../components/header/header';
import Footer from '../components/footer/footer';
import Timer from '../components/timer/timer';
import Chart from '../components/chart/chart';
import MainChart from '../components/main-chart/main-chart';

import * as styles from '../scss/pages/index.module.scss';
import '../scss/styles.scss';

const IndexPage = () => {
	const { aslan: { homepages } } = useStaticQuery( pageQuery );

	const content = homepages[0];

	const colors = ['#FF5D5D', '#FFFFFF', '#FFD9BD', '#F9A2A2', '#39C7FF', '#F1A900', '#E25EB2', '#2A6AD8', '#A3F5E7'];

	return(
		<>
			<Seo title={content.seoTitle} description={content.seoDescription} />
			<Scroller />
			<section className={styles.sectionMain}>
				<div className={styles.imageWrapper}>
					<img src="/img/mountains.svg" alt="Mountains" />
				</div>
				<div className={styles.imageWrapperWaves}>
					<img src="/img/waves.svg" alt="Waves" />
				</div>

				<Header />

				<div className="container">
					<div className={styles.copy}>
						<h1>{content.mainTitle}</h1>
						<h3>{content.mainSubtitle}</h3>

						<Timer date={content.presaleDate} activePresaleText={content.activePresale} />
					</div>
				</div>
			</section>

			<section id="aslan" className={styles.sectionAslan}>
				<div className="container">
					<div className={styles.aslanWrapper}>
						<div className={styles.aslanCopy}>
							<h2>{content.aslanTitle}</h2>
							<div dangerouslySetInnerHTML={ { __html: content.aslanContent.html } } />
						</div>
						<img src="/img/aslan-on-rocket.svg" alt="Aslan on the rocket" placeholder="none" />
					</div>
				</div>
			</section>

			<section className={styles.sectionBNB}>
				<div className="container">
					<div className={styles.bnbWrapper}>
						<img src="/img/aslan-bnb.svg" alt="Aslan & BNB" placeholder="none" />
					</div>
				</div>
			</section>

			<section className={styles.sectionTokenomics}>
				<div id="tokenomics" className={styles.tokenomics}>
					<div className="container">
						<h2>Tokenomics</h2>

						<div className={styles.charts}>
							<MainChart tokenomics={content.tokenomics} colors={colors} />

							<div className={styles.chartsWrapper}>
								{content.tokenomics.map( ( chart, index ) => (
									<Chart
										key={chart.id}
										label={chart.label}
										color={colors[index]}
										percentage={chart.percentage}
									/>
								) )}
							</div>
						</div>
					</div>
				</div>
				<div id="roadmap" className={styles.roadmap}>
					<div className="container">
						<h2>Roadmap</h2>

						<div className={styles.roadmapWrapper}>

							{content.roadmaps && content.roadmaps.map( r => (
								<div key={r.id} className={styles.roadmapItem}>
									<div className={styles.roadmapDivider}>
										<h3>{r.quarter}</h3>
										<div dangerouslySetInnerHTML={ { __html: r.content.html } } />
									</div>
								</div>
							) )}
						</div>
					</div>

					<div className={styles.roadmapMoon}>
						<img src="/img/moon.svg" alt="Moon" placeholder="none" />
					</div>
				</div>
			</section>

			<section id="community" className={styles.sectionContact}>
				<div className="container">
					<div className={styles.contactWrapper}>
						{content.telegramLink && (
							<a href={content.telegramLink} target="_blank" rel="noreferrer">
								<img src="/img/icons/telegram.svg" alt="Telegram icon" placeholder="none" />
							</a>
						)}

						{content.twitterLink && (
							<a href={content.twitterLink} target="_blank" rel="noreferrer">
								<img src="/img/icons/twitter.svg" alt="Twitter icon" placeholder="none" />
							</a>
						)}

						{content.facebookLink && (
							<a href={content.facebookLink} target="_blank" rel="noreferrer">
								<img src="/img/icons/facebook.svg" alt="Facebook icon" placeholder="none" />
							</a>
						)}

						{content.linkedinLink && (
							<a href={content.linkedinLink} target="_blank" rel="noreferrer">
								<img src="/img/icons/linkedin.svg" alt="Linkedin icon" placeholder="none" />
							</a>
						)}
					</div>
				</div>
			</section>

			<section id="lightpaper" className={styles.sectionLightpaper}>
				<div className="container">
					<div className={styles.lightpaperWrapper}>
						<div className={styles.lightpaperCopy}>
							<h2>{content.lightpaperTitle}</h2>
							<div dangerouslySetInnerHTML={ { __html: content.lightpaperContent.html } } />
						</div>

						<img src="/img/aslan-astronaut.svg" alt="Aslan astronaut" placeholder="none" />
					</div>
				</div>
			</section>

			<Footer content={content.footerContent.html} />
		</>
	);
};

const pageQuery = graphql`
        query PostsQuery {
          aslan {
		    homepages(last: 1) {
		      mainSubtitle
		      mainTitle
		      presaleDate
		      activePresale
		      aslanTitle
		      aslanContent {
		        html
		      }
		      tokenomics {
		        id
		        label
		        percentage
		      }
		      roadmaps {
		        id
		        quarter
		        content {
		          html
		        }
		      }
		      lightpaperTitle
		      lightpaperContent {
		        html
		      }
		      footerContent {
		        html
		      }
		      telegramLink
		      twitterLink
		      facebookLink
		      linkedinLink
		      seoTitle
		      seoDescription
		    }
		  }
        }
      `;

export default IndexPage;
