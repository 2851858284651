import React from 'react';
import * as styles from './scroller.module.scss';

const Scroller = () => {

	const scrollTop = () => {
		window.scrollTo( {
			'behavior': 'smooth',
			'left': 0,
			'top': 0
		} );
	};

	return(
		<div className={styles.scroller} onClick={scrollTop}>
			<img src="/img/icons/arrow.svg" alt="Arrow icon"/>
		</div>
	);
};

export default Scroller;
