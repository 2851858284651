import React from 'react';
import { Chart as ChartJS, ArcElement  } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import * as styles from './main-chart.module.scss';

ChartJS.register( ArcElement );

const MainChart = ( { tokenomics, colors } ) => {
	const percentages = tokenomics.map( t => t.percentage );

	const data = {
		datasets: [
			{
				data: percentages,
				backgroundColor: colors,
				borderWidth: 0
			}
		]
	};

	const options = {
		events: [],
		cutout: '80%'
	};

	return(
		<div className={styles.chart}>
			<Doughnut type="doughnut" data={data} options={options} />

			<div className={styles.imageWrapper}>
				<img src="/img/aslan.svg" alt="Aslan" placeholder="none" />
			</div>
		</div>
	);
};

export default MainChart;
