import React from 'react';
import * as styles from './footer.module.scss';

const Footer = ( { content } ) => (
	<footer className={styles.footer}>
		<div className="container">
			<div dangerouslySetInnerHTML={ { __html: content } } />
		</div>
	</footer>
);

export default Footer;
