// extracted by mini-css-extract-plugin
export var aslanCopy = "index-module--aslanCopy--+tsra";
export var aslanWrapper = "index-module--aslanWrapper--Y3ssd";
export var bnbWrapper = "index-module--bnb-wrapper--MBg1E";
export var charts = "index-module--charts--spXOd";
export var chartsWrapper = "index-module--chartsWrapper--tcElo";
export var contactWrapper = "index-module--contactWrapper--fG0yX";
export var copy = "index-module--copy--NdDey";
export var imageWrapper = "index-module--imageWrapper--84Mzo";
export var imageWrapperWaves = "index-module--imageWrapperWaves--ySTmG";
export var lightpaperCopy = "index-module--lightpaperCopy--brgGX";
export var lightpaperWrapper = "index-module--lightpaperWrapper--56JgW";
export var roadmap = "index-module--roadmap--p3g2n";
export var roadmapDivider = "index-module--roadmapDivider--PGjSn";
export var roadmapItem = "index-module--roadmapItem--NulG-";
export var roadmapMoon = "index-module--roadmapMoon--ux8HH";
export var roadmapWrapper = "index-module--roadmapWrapper--etHFg";
export var sectionAslan = "index-module--sectionAslan---Cvhm";
export var sectionBNB = "index-module--sectionBNB--hreE1";
export var sectionContact = "index-module--sectionContact--BeOgx";
export var sectionLightpaper = "index-module--sectionLightpaper--qXnk+";
export var sectionMain = "index-module--sectionMain--iTiDS";
export var sectionTokenomics = "index-module--sectionTokenomics--wtvDl";
export var tokenomics = "index-module--tokenomics--bnP0u";