import React from 'react';
import { Chart as ChartJS, ArcElement  } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import * as styles from './chart.module.scss';

ChartJS.register( ArcElement );

const Chart = ( { percentage, label, color } ) => {
	const data = {
		datasets: [
			{
				data: [percentage, 100 - percentage],
				backgroundColor: [
					color,
					'#572080'
				],
				borderWidth: 0
			}
		]
	};

	const options = {
		events: [],
		cutout: '80%'
	};

	return(
		<div>
			<div className={styles.chartWrapper}>
				<Doughnut type="doughnut" data={data} options={options} />

				<div className={styles.number}>
					{percentage + '%'}
				</div>
			</div>

			<div className={styles.label}>
				{label}
			</div>
		</div>
	);
};

export default Chart;
