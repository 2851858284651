import React, { useState } from 'react';
import { Link } from 'gatsby';
import * as styles from './header.module.scss';

const Header = () => {
	const [isHeaderActive, setIsHeaderActive] = useState( false );


	return (
		<header className={`${styles.header} ${isHeaderActive ? 'active' : ''}`}>
			<div className="container">
				<div className={styles.headerWrapper}>
					<Link to="/">
						<img src="/img/logo.svg" alt="logo" />
					</Link>

					<div
						onClick={() => setIsHeaderActive( !isHeaderActive )}
						className={styles.headerTrigger}
					>
						<span />
						<span />
						<span />
					</div>

					<nav>
						<ul>
							<li>
								<Link to="#aslan">Aslan</Link>
							</li>
							<li>
								<Link to="#tokenomics">Tokenomics</Link>
							</li>
							<li>
								<Link to="#roadmap">Roadmap</Link>
							</li>
							<li>
								<Link to="#community">Community</Link>
							</li>
							<li>
								<Link to="#lightpaper">Lightpaper</Link>
							</li>
						</ul>
					</nav>
				</div>
			</div>
		</header>
	);
};

export default Header;
